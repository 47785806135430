export function autoCloseDiv(divId, seconds) {
    const div = document.getElementById(divId);
    if (!div) {
        console.error(`Div with ID "${divId}" not found.`);
        return;
    }

    setTimeout(() => {
        div.style.display = 'none';
        console.log(`Div with ID "${divId}" is now hidden.`);
    }, seconds * 1000);
}